import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import { UserAuth } from '../../../context/AuthContext';
import FacilitaNutri from '../../../img/FacilitaNutri.svg'; // Importe sua imagem de logo aqui
import '../../Sign/SignUp/Signup.css'; // Importe o CSS de Signin aqui
import 'react-phone-number-input/style.css';
import Mainloading from '../../MainLoading/loading'


const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [Numero, setNumero] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [termoscheck, setTermosCheck] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { createUser } = UserAuth();
  const navigate = useNavigate();

  const handleCheck = () => {
    setTermosCheck(!termoscheck);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      if (password !== confirmPassword) {
        setError('As senhas não são iguais. Por favor, verifique novamente.');
        return;
      }
      if (!termoscheck) {
        setError('Você deve concordar com os termos e condições para criar uma conta');
        return;
      }

      setLoading(true);
      await createUser(email, password, Numero);
      setLoading(false);
      navigate('/home');
    } catch (e) {
      setLoading(false);
      if (e.message === 'Firebase: Error (auth/invalid-email).') {
        setError('Email inválido. Cadastre com email corretamente');
      } else if (e.message === 'Firebase: Error (auth/email-already-in-use).') {
        setError('Email já existente. Clique no botão entrar com conta abaixo');
      } else if (e.message === 'Firebase: Password should be at least 6 characters (auth/weak-password).') {
        setError('Sua senha precisa ter mais de 6 caracteres');
      }
    }
  };

  return (<div>
    {loading && ( <div  className="flex justify-center items-center h-screen">
      <Mainloading/>
    </div>)}  
  
    <div className="signup-container">
      <div className="signup-card">
            <div className="text-center">
        <img src={FacilitaNutri} alt="Logo" className="logo-image" />
          <h2 className="signup-heading">Facilita Nutri - Registrar</h2>
        </div>
        <br></br>
        <form onSubmit={handleSubmit} className="signup-form">
          <div className="form-group">
            <input
              onChange={(e) => setEmail(e.target.value)}
              className="form-input"
              type="email"
              placeholder="Email"
              required
            />
          </div>
          <div className="form-group">
            <div className="relative">
              <input
                onChange={(e) => setPassword(e.target.value)}
                className="form-input"
                type={showPassword ? 'text' : 'password'}
                placeholder="Senha"
                required
              />
              <span className="toggle-password" onClick={togglePasswordVisibility}>
                <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
              </span>
            </div>
          </div>
          <div className="form-group">
            <input
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="form-input"
              type="password"
              placeholder="Confirme sua senha"
              required
            />
          </div>
          <div className=" form-group">

            <PhoneInput
              onChange={(value) => {
                setNumero(value)
              }}
              className="form-input mb-0 mt-2 "
              type="text"
              placeholder="Numero"
              defaultCountry='BR'
            />
          </div>
          <br></br>
          <div className="form-group flex items-start my-0">
  <input
    type="checkbox"
    onChange={handleCheck}
    checked={termoscheck}
    className="form-checkbox "
    id="termosCheck"
    style={{ width: "1.4em", height: "1.4em", marginTop: "2px" }} // Ajuste o tamanho e o espaçamento aqui
  />
  <label htmlFor="termosCheck" className="ml-2 text-sm text-gray-700" style={{ marginBottom: "4px" }}> {/* Ajuste o espaçamento inferior aqui */}
    Eu estou de acordo com os{' '}
    <Link to="/termos" target="_blank" className="text-[#48be03] font-semibold">
      Termos e Condições de uso
    </Link>{' '}
    do Facilita Nutri
  </label>
</div>
<br></br>
{error && <p className="error-message">{error}</p>}
          
          <button className="signup-button">CRIAR CONTA</button>
        </form>
        <div className="signup-links">
          <p>
            <Link to="/" className="signin-link">
              ENTRAR COM CONTA
            </Link>
          </p>
        </div>
      </div>
    </div>
    </div> );
};

export default Signup;