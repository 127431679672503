import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';
import { Menu, X, User, BatteryCharging, Coffee, HelpCircle, LogOut } from 'react-feather';

function HamburgerMenu({ isOpen, toggleMenu, tickets }) {
  const { user, logout } = UserAuth();
  const [userTickets, setUserTickets] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setUserTickets(tickets);
  }, [tickets]);

  const menuItems = [
    { label: 'Progresso', icon: <BatteryCharging size={20} />, action: () => navigate('/sobre') },
    { label: 'Dieta', icon: <Coffee size={20} />, action: () => navigate('/dieta') },
    { label: 'Suporte', icon: <HelpCircle size={20} />, action: () => navigate('/suporte') },
    { label: 'Sair', icon: <LogOut size={20} />, action: handleLogout, className: 'text-red-500 hover:bg-red-100' },
  ];

  async function handleLogout() {
    try {
      await logout();
      navigate('/');
    } catch (e) {
      console.error('Logout failed', e);
    }
  }

  return (
    <div className="relative">
      <button
        className="p-2 text-gray-600 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        onClick={toggleMenu}
        aria-label="Toggle menu"
      >
        {isOpen ? <X size={24} /> : <Menu size={24} />}
      </button>

      {isOpen && (
        <div className="fixed inset-0 z-50 overflow-hidden">
          <div className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={toggleMenu}></div>
          <div className="absolute inset-y-0 right-0 max-w-full flex">
            <div className="relative w-screen max-w-md">
              <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                <div className="px-4 sm:px-6">
                  <div className="flex items-start justify-between">
                    <h2 className="text-lg font-medium text-gray-900">Menu</h2>
                    <button
                      className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                      onClick={toggleMenu}
                    >
                      <span className="sr-only">Close panel</span>
                      <X size={24} aria-hidden="true" />
                    </button>
                  </div>
                </div>
                <div className="mt-6 relative flex-1 px-4 sm:px-6">
                  <div className="flex flex-col items-center pb-6 border-b">
                    <div className="h-24 w-24 rounded-full bg-green-100 flex items-center justify-center text-green-500">
                      <User size={48} />
                    </div>
                    <h3 className="mt-3 text-lg font-medium text-gray-900">Bem-vindo(a)!</h3>
                    <p className="mt-1 text-sm text-gray-500">{user && user.email}</p>
                    <p className="mt-1 text-sm font-semibold text-green-600">Saldo: {userTickets}</p>
                  </div>
                  <nav className="mt-6">
                    <ul className="space-y-1">
                      {menuItems.map((item, index) => (
                        <li key={index}>
                          <button
                            onClick={item.action}
                            className={`w-full flex items-center px-4 py-2 text-sm font-medium rounded-md ${
                              item.className || 'text-gray-600 hover:bg-gray-100 hover:text-gray-900'
                            }`}
                          >
                            {item.icon}
                            <span className="ml-3">{item.label}</span>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

HamburgerMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  tickets: PropTypes.number,
};

export default HamburgerMenu;