
import React from 'react';
import '../../Styles/Desafio.css';
import SubscriptionComponent from '../../MercadoPago/SubscriptionComponent/Subscription.component';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserAuth } from '../../../context/AuthContext';






export default function DietaTreino() {
  const addOrderBumpT = true;

  const link = 'https://pay.kiwify.com.br/g4CGZlP'
  const {atendimentoNutricionista} = UserAuth()
  const navigate = useNavigate();

    const baseAmount = 29.99;
    

  const handlePaymentWaiting = () => {
    // Precisa ser melhor planejado/elaborado

    toast.info('Pagamento pendente. Por favor, finalize o pagamento via Pix e volte para essa pagina!')

  };

  const handlePaymentSuccess = () => {
    navigate('/comprarealizada');
    atendimentoNutricionista(true)
  };

  return (
    <div className="payment-page">

  
      <div className="order-bump-container bg-gray-5 text-center  flex justify-center items-center">
  <div className= "bg-white shadow rounded-3xl px-3 py-2 mx-auto" style={{ maxWidth: '390px', padding: '10px' }}>
    <div className="order-bump-title mb-0.5 flex justify-center items-center">
      <h2 className="text-lg text-green-500  font-semibold inline-flex items-center">Consulta com a Nutricionista <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 ml-2" /></h2>
    </div>
    <div className="p-0">
      <p className="text-gray-700 text-sm m-0">Consulta com a nutricionista <strong>incluída</strong>!</p>
    </div>
  
  </div>
</div>

      <div className="payment-method-selector">
        <SubscriptionComponent amount={baseAmount} onPaymentSuccess={handlePaymentSuccess} onPaymentWaiting={handlePaymentWaiting} addOrderBump={addOrderBumpT} linkexterno={link} />
      </div>

     
    </div>
  );
}