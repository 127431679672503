import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaAppleAlt, FaShoppingCart, FaSync, FaThumbsUp, FaLock } from 'react-icons/fa';
import SubscriptionComponent from '../../MercadoPago/SubscriptionComponent/Subscription.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

function OrderBumpOverlay({ onConfirm, onCancel, isOpen }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full overflow-hidden">
        <div className="p-6">
          <p className="text-green-600 font-semibold mb-4 text-center">Por apenas R$ 2,98 a mais!</p>
          <div className="space-y-3 mb-6">
            {[
              { icon: FaSync, text: "Calorias e gramas + 3 opções" },
              { icon: FaThumbsUp, text: "Recomendação Whey e Creatina" },
              { icon: FaAppleAlt, text: "Receitas fit, Lista de substituições" },
              { icon: FaShoppingCart, text: "Lista de compras dos alimentos" }
            ].map(({ icon: Icon, text }, index) => (
              <div key={index} className="flex items-center bg-gray-50 p-2 rounded">
                <Icon className="text-green-500 mr-3 h-5 w-5" />
                <span className="text-sm">{text}</span>
              </div>
            ))}
          </div>
          <div className="flex space-x-4">
            <button
              onClick={onCancel}
              className="flex-1 px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
            >
              Não, obrigado
            </button>
            <button
              onClick={onConfirm}
              className="flex-1 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
            >
              Sim, quero incluir
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function DietaTreino() {
  const [addOrderBump, setAddOrderBump] = useState(false);
  const [amount, setAmount] = useState(16.99);
  const [showOrderBumpOverlay, setShowOrderBumpOverlay] = useState(true);
  const navigate = useNavigate();

  const handleConfirmOrderBump = () => {
    setAddOrderBump(true);
    setShowOrderBumpOverlay(false);
    setAmount(16.99 + 2.98);
  };

  const handleCancelOrderBump = () => {
    setShowOrderBumpOverlay(false);
  };

  const handlePaymentWaiting = () => {
    toast.info('Pagamento pendente. Por favor, finalize o pagamento via Pix e volte para essa página!');
  };

  const handlePaymentSuccess = () => {
    navigate('/comprarealizada');
  };

  return (
    <div className="relative min-h-screen overflow-hidden">
      <div className="absolute inset-0 bg-gray-100 backdrop-filter backdrop-blur-sm"></div>
      <div className="relative z-10 min-h-screen flex flex-col items-center justify-center p-2 sm:p-4">
        <OrderBumpOverlay
          onConfirm={handleConfirmOrderBump}
          onCancel={handleCancelOrderBump}
          isOpen={showOrderBumpOverlay}
        />

<div className="bg-white rounded-lg shadow-xl overflow-hidden w-full max-w-[99%] sm:max-w-6xl">
<div className="p-3 sm:p-6">
            <div className="flex flex-col lg:flex-row">
              <div className="lg:w-1/2 lg:pr-4">
              <h3 className="text-xl text-green-600 font-semibold mb-3">Resumo do Pedido 🛒</h3>
              <div className="bg-green-50 p-3 sm:p-4 rounded-lg mb-4">
                  <div className="text-green-600 flex justify-between mb-2">
                    <span>Dieta para Treino</span>
                    <span className="font-semibold">R$ 16,99</span>
                  </div>
                  {addOrderBump && (
                    <div className="flex justify-between text-green-600">
                      <span>Adicionais Personalizados</span>
                      <span className="font-semibold">R$ 2,98</span>
                    </div>
                  )}
                  <div className="border-t border-green-300 mt-2 pt-2 flex justify-between font-semibold">
                    <span>Total</span>
                    <span>R$ {amount.toFixed(2)}</span>
                  </div>
                </div>

             
              </div>

              <div className="lg:w-1/2 lg:pl-4 border-t lg:border-t-0 lg:border-l border-gray-200 pt-4 lg:pt-0 mt-4 lg:mt-0">
                <div className="bg-white border border-gray-200 rounded-lg p-3 sm:p-4 mb-4">
                  <div className="flex items-center mb-4">
                    <FaLock className="text-green-500 mr-2 text-lg" />
                    <span className="text-sm text-green-600">Pagamento 100% Seguro</span>
                  </div>
                  <SubscriptionComponent
                    amount={amount}
                    onPaymentSuccess={handlePaymentSuccess}
                    onPaymentWaiting={handlePaymentWaiting}
                    addOrderBump={true}
                  />
                </div>
              </div>
            </div>

            <div className="mt-4">
              <p className="text-center text-sm text-gray-600">
                Dieta gerada após o pagamento. Por favor, volte ao site para acessar sua dieta.
                <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 ml-1" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}