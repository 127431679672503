import React from 'react';
import { jsPDF } from 'jspdf';


export default function ReceitasPdf() {
    const downloadPdf = () => {
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const headerHeight = 50; 
        const lineHeight = 10;
        const primaryColor = [29, 158, 34];

        doc.setFillColor(...primaryColor);
        doc.rect(0, 0, pageWidth, 40, 'F');
        const title = 'Receitas Fit'
        const subtitle = 'Facilita Nutri'
         // Add title
         doc.setTextColor(255);
        doc.setFontSize(24);
        doc.setFont('helvetica', 'bold');
        doc.text(title, pageWidth / 2, 20, { align: 'center' });

        doc.setFontSize(16);
        doc.setFont('helvetica', 'normal');
        doc.text(subtitle, pageWidth / 2, 30, { align: 'center' });

        doc.setTextColor(0);
        doc.setFontSize(11);
        doc.setFont('helvetica', 'normal');
        const receitasFit = `
        
    Pizza Fit:

    1. Pizza de Frango com Aveia
    Ingredientes:
    - 1 xícara de farinha de aveia
    - 1 ovo
    - 1 colher de sopa de azeite
    - 1 colher de chá de fermento em pó
    - 1 pitada de sal
    - 1 peito de frango cozido e desfiado
    - 1/2 xícara de molho de tomate
    - 1 xícara de queijo muçarela ralado
    - 1 tomate fatiado
    - Orégano a gosto

    Modo de preparo:
    Misture a farinha de aveia, o ovo, o azeite, o fermento e o sal até formar uma massa homogênea. 
    Abra a massa em uma forma para pizza. Asse em forno pré-aquecido a 180°C por cerca de 10 minutos. 
    Retire do forno, espalhe o molho de tomate, distribua o frango desfiado, o queijo, as fatias de tomate e orégano. 
    Leve ao forno novamente por mais 10 minutos ou até o queijo derreter.

    2. Pizza de Couve-Flor
    Ingredientes:
    - 1 cabeça de couve-flor
    - 1 ovo
    - 1/2 xícara de queijo parmesão ralado
    - Sal e pimenta a gosto
    - Molho de tomate
    - Coberturas de sua preferência (vegetais, frango, atum, etc.)

    Modo de preparo:
    Triture a couve-flor em um processador até obter uma textura de arroz. 
    Cozinhe no micro-ondas por cerca de 8 minutos. Deixe esfriar e esprema para retirar o excesso de água. 
    Misture a couve-flor com o ovo, queijo parmesão, sal e pimenta. 
    Espalhe a mistura em uma forma para pizza, formando uma camada fina. 
    Asse em forno pré-aquecido a 200°C por cerca de 20 minutos. 
    Retire do forno, adicione o molho de tomate e as coberturas de sua escolha. Asse por mais 10 minutos.


    Panquecas de Aveia e Banana

    Ingredientes:
    - 1 banana madura amassada
    - 2 ovos
    - 1/2 xícara de aveia em flocos
    - 1 colher de chá de canela em pó
    - 1 colher de chá de fermento em pó

    Modo de preparo:
    Misture todos os ingredientes até formar uma massa homogênea.
    Aqueça uma frigideira antiaderente e despeje pequenas porções da massa.
    Cozinhe cada lado por cerca de 2 minutos ou até dourar.
    Sirva quente com frutas frescas ou um fio de mel.


    Tapioca + Suco Nutritivo:

    Tapioca de Chia com Ovo Cozido

    Tempo de preparo: 23 minutos (inclui o tempo de cozimento do ovo)
    Característica: Prática e fácil
    Rendimento: 1 porção
    Custo: R$3,75
    Ingredientes:

    3 colheres de sopa de goma de tapioca hidratada
    1 colher de sopa de chia
    1 ovo cozido amassado

    Modo de preparo:
    Peneire a tapioca e misture com a chia.
    Leve ao fogo até que a massa comece a se desprender da frigideira.
    Adicione o ovo amassado.
    Dobre a tapioca ao meio.
    Suco Verde de Abacaxi, Couve e Maçã

    Tempo de preparo: 6 minutos (não inclui o tempo de higienização)
    Característica: Rápido e fácil
    Rendimento: 5 copos
    Ingredientes:

    4 fatias de abacaxi
    3 folhas de couve
    4 folhas de hortelã
    1 maçã grande com casca
    400 mL de água ou água de coco

    Modo de preparo:
    Higienize a couve, a hortelã e a maçã.
    Descasque o abacaxi e corte em fatias.
    Coloque todos os ingredientes no liquidificador.
    Adicione a água aos poucos, conforme necessário.


    Shake de Café e Banana
    
    Ingredientes:
    - 1 banana congelada
    - 1 xícara de café gelado
    - 1/2 xícara de leite de amêndoas (ou qualquer leite vegetal)
    - 1 colher de sopa de proteína em pó de baunilha ou chocolate (opcional)
    - 1 colher de chá de canela
    - Gelo a gosto
    
    Modo de preparo:
    Coloque todos os ingredientes no liquidificador.
    Bata até obter uma mistura homogênea e cremosa.
    Sirva imediatamente para aproveitar os nutrientes e a energia.
    Este shake combina a energia natural do café com a banana, que é rica em potássio, para um impulso energético que também ajuda a manter a saciedade.

    2. Shake Verde Detox
    
    Ingredientes:
    - 1 xícara de espinafre cru
    - 1 maçã verde, sem sementes e cortada em pedaços
    - 1/2 pepino cortado
    - Suco de 1 limão
    - 1 colher de sopa de gengibre fresco ralado
    - 1 xícara de água de coco
    - Gelo a gosto
    - Modo de preparo:

    Adicione todos os ingredientes ao liquidificador.
    Bata até que a mistura esteja lisa e uniforme.
    Sirva gelado.
    Este shake é perfeito para desintoxicação e promoção da digestão, com ingredientes que ajudam a limpar o organismo e estimular o metabolismo.

    3. Shake de Morango e Chia
    
    Ingredientes:
    - 1 xícara de morangos frescos ou congelados
    - 1 xícara de iogurte grego sem gordura
    - 1 colher de sopa de sementes de chia
    - 1/2 xícara de leite de amêndoas
    - Adoçante a gosto (opcional)
    - Gelo a gosto
    
    Modo de preparo:
    Coloque os morangos, o iogurte, o leite de amêndoas e as sementes de chia no liquidificador.
    Adicione adoçante se desejar.
    Bata tudo até ficar cremoso e homogêneo.
    Adicione gelo e bata um pouco mais para refrescar.
    Este shake é rico em proteínas e fibras, graças ao iogurte grego

    Hambúrguer

    Mandioca: 2 xícaras (colheres de sopa)
    Cebolinha: ½ xícara (colheres de chá)
    Manjericão seco: ½ xícara (colheres de chá)
    Quinoa em grão: 100g
    Azeite: 3 colheres de sopa
    Sal e pimenta-do-reino a gosto
    
    PREPARO DE HAMBÚRGUER

    Em uma panela, coloque água e quinoa. Cozinhe e lave a quinoa.
    Quando a quinoa estiver cozida, acrescente as ervas e o azeite.
    Molde os hambúrgueres.
    Em uma frigideira, coloque a farinha e doure os hambúrgueres até ficarem bem misturados.

    Sorvete de banana proteico

    Modo de Preparo 

    - 2 bananas
    - 1 scoop de whey
    Corte as bananas em rodelas e congele.
    Coloque as bananas congeladas em um processador até formar uma massa cremosa.
    Adicione o whey e misture bem.
    Está pronto em pouco tempo.

    Barrinhas de Cereais Caseiras

    Ingredientes:
    - 1 xícara de aveia em flocos
    - 1/2 xícara de amêndoas picadas
    - 1/4 xícara de mel
    - 1/4 xícara de manteiga de amendoim
    - 1/2 xícara de frutas secas (como cranberries ou passas)

    Modo de preparo:
    Aqueça o mel e a manteiga de amendoim até que se misturem bem.
    Em uma tigela, misture a aveia, as amêndoas e as frutas secas.
    Despeje a mistura de mel e manteiga de amendoim e misture bem.
    Espalhe em uma forma e pressione firmemente.
    Leve à geladeira por pelo menos 2 horas antes de cortar em barras.

    Crepioca de Banana com Canela
    
    Ingredientes:
    - 2 colheres de sopa de goma de tapioca hidratada
    - 1 ovo
    - 1 banana amassada
    - 1/2 colher de chá de canela em pó
    - Mel ou adoçante natural a gosto (opcional)
    
    Modo de preparo:

    Em uma tigela, misture a goma de tapioca com o ovo até obter uma mistura homogênea.
    Aqueça uma frigideira antiaderente e despeje a mistura, espalhando bem como uma panqueca.
    Distribua a banana amassada por cima e polvilhe com canela.
    Deixe cozinhar em fogo baixo até que a crepioca esteja firme e dourada dos dois lados.
    Sirva com um fio de mel ou adoçante natural, se desejar.

    Tapioca de Frango e Cottage

    Ingredientes:
    - 2 colheres de sopa de goma de tapioca hidratada
    - 100g de peito de frango cozido e desfiado
    - 2 colheres de sopa de queijo cottage
    - Sal e pimenta-do-reino a gosto
    - Folhas de espinafre a gosto

    Modo de preparo:

    Aqueça uma frigideira antiaderente e espalhe a goma de tapioca uniformemente até cobrir o fundo da frigideira.
    Deixe cozinhar até que as bordas comecem a desgrudar. Não é necessário virar.
    Adicione o frango desfiado e o queijo cottage por cima da tapioca.
    Tempere com sal e pimenta e adicione as folhas de espinafre.
    Dobre a tapioca ao meio e deixe no fogo por mais um minuto.
    Sirva quente.




    
    
    `;

        const splitText = doc.splitTextToSize(receitasFit, 180);
        let yPos = headerHeight ; // Posição inicial do texto abaixo da imagem

        // Adicionar texto e criar novas páginas se necessário
        splitText.forEach((line) => {
            if (yPos + lineHeight > pageHeight) {
                doc.addPage();
                yPos = 10; // Resetar posição do texto para o topo da nova página
            }
            doc.text(line, 10, yPos);
            yPos += lineHeight;
        });

        // Salvar PDF
        doc.save("Receitas Fit.pdf");
    };

    return (
        <button className="flex border text-sm md:text-base items-center mb-2 p-5 shadow-lg rounded-lg  text-black w-full h-[50px] lg:h-[40px]  " onClick={downloadPdf}>
         <h1 className='pr-2 m-0'>🥗</h1> Receitas Fitness
      </button>
      
    );
}