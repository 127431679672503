import React from 'react';
import CountDown from '../../CountDown/CountDown';
import Beneficios from '../Vantagens/Beneficios';
import Clientes from '../Vantagens/Clientes';

const WhatsAppCard = () => {
  return (
    <div className="flex flex-col items-center bg-background p-6 rounded-lg shadow-lg font-sans">
      <span className="bg-green-100 text-green-600 text-xs font-semibold px-2.5 py-0.5 rounded">
        Para Nutricionistas e Personal
      </span>
      <br />
      <h2 className="text-2xl font-semibold text-foreground mb-1">
        Crie Dietas em Segundos
      </h2>

      <p className="text-muted-foreground text-center mb-0">
        Com base nos alimentos favoritos e objetivos
      </p>
      <a
  href="https://wa.me/5524992178767"
  target="_blank"
  rel="noopener noreferrer"
  className="bg-[#17A34A] text-white hover:bg-[#17A34A]/80 mt-4 px-4 py-2 rounded-full inline-block text-center no-underline"
>
Fazer um teste gratis
</a>
      <CountDown />
      <Beneficios />
      <Clientes/>

    </div>
  );
};

export default WhatsAppCard;
