import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import '../Styles/Desafio.css';
import { UserAuth } from '../../context/AuthContext';
import { addPaymentEFI, getPaymentEFI, removePaymentEFI, getPaymentStartTimeEFI, setPaymentStartTimeEFI, removePaymentStartTimeEFI } from '../../helpers/localStorage.helper';
import QRCode from 'qrcode.react';
import formatCurrency from '../../helpers/formatCurrency.helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import {  ClipboardCopyIcon, QrCode, X } from 'lucide-react';






function QRCodeIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect x="2" y="2" width="8" height="8" />
      <rect x="14" y="2" width="8" height="8" />
      <rect x="2" y="14" width="8" height="8" />
      <rect x="14" y="14" width="8" height="8" />
    </svg>
  );
}


export default function GatewayPagamento({amount, addOrderBump, linkexterno, handlePaymentSuccess, handlePaymentWaiting}) {
  const {CriarPagamento, VerificarPagamento, CancelarPagamento, user} = UserAuth()
  const [paymentEFI, setPaymentEFI] = useState(getPaymentEFI() || null)
  const [email, setEmail] = useState('')
  const uid = user.uid

  const [visibleQr, setVisibleQr] = useState(false)
  const[payerFirstName, setPayerFirstName] = useState('')
  const handlePaymentEFI = async(event) =>{
    event.preventDefault();
    try{
      const payment = await CriarPagamento(amount.toString(), addOrderBump, email, uid )
      addPaymentEFI(payment === undefined ? removePaymentEFI() : payment)
      setPaymentEFI(payment === undefined ? removePaymentEFI() : payment)
      setPaymentStartTimeEFI();
      if (payment !== undefined){
      } else{
        toast.error('Pagamento não iniciado')
      }
      
    }catch(error){
      toast.error('Erro ao Criar Pagamento')
      removePaymentEFI()
    }


  }

  const handlePaymentStatus = useCallback(async () => {
    const paymentStartTime = getPaymentStartTimeEFI();
    const paymentEFIID = paymentEFI ? paymentEFI.txid : null
    const currentPaymentId = paymentEFIID;

    if (!currentPaymentId) {
      console.error('Erro: ID do pagamento não encontrado.');
      return;
    }

      const timeElapsed = Date.now() - paymentStartTime;
      if (timeElapsed > 1200000) {
        // Se passaram 10 minutos, para as verificações
      await CancelarPagamento(currentPaymentId)
      setPaymentEFI(null)
      removePaymentEFI()
      setEmail('')
      setPayerFirstName('')
      removePaymentStartTimeEFI()
        // Notifica o usuário sobre o tempo expirado
        toast.error('Pagamento expirado. Inicie um novo pagamento.');
        return;
      }

      const payment = await VerificarPagamento(currentPaymentId)
      if (payment.status === 'CONCLUIDA'){
        console.log('Pagamento Concluido')
        removePaymentEFI()
        setPaymentEFI(null)
        handlePaymentSuccess()
      } else if(payment.status === 'ATIVA'){
        handlePaymentWaiting()
      }



  }, [VerificarPagamento, handlePaymentSuccess, handlePaymentWaiting, paymentEFI, CancelarPagamento]);

  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {

    const handleWindowFocus = () => {
      handlePaymentStatus()
    };

    const handleLoad = () => {
      setPageLoaded(true);
    };
  if(paymentEFI !== null){
    window.addEventListener('focus', handleWindowFocus);
  }

  let timer;
  const paymentEFIID =  paymentEFI ? paymentEFI.txid : null
  const currentPaymentId = paymentEFIID;
  if (currentPaymentId) {
  window.addEventListener('load', handleLoad);
  timer = setTimeout(() => {
      if (pageLoaded) {

      }
        handleWindowFocus();

    }, 1000); // 2000 milissegundos = 2 segundos
  }

  return () => {
    if (timer) {
      clearTimeout(timer); // Limpa o temporizador
    }
    window.removeEventListener('focus', handleWindowFocus);
    window.removeEventListener('load', handleLoad);
  };
}, [handlePaymentStatus,paymentEFI, pageLoaded]);



  const copiarParaAreaDeTransferencia = (codigo) => {
    navigator.clipboard.writeText(codigo)
      .then(() => {
        alert('Código copiado para a área de transferência!');
      })
      .catch(err => {
        console.error('Erro ao copiar o código: ', err);
      });
  };

  const handleCancelarPaymentEFI = async (paymentIdEFI) =>{
    try{
       await CancelarPagamento(paymentIdEFI)
      setPaymentEFI(null)
      removePaymentEFI()
      setEmail('')
      setPayerFirstName('')
      removePaymentStartTimeEFI()
    }catch(error){
      toast.error('Erro ao Cancelar Pagamento')
    }


  }






  return (
    <div className="mx-auto text-center">

      {!paymentEFI && (<>

  

      <div className="w-full max-w-md">

        {/* Checkout */}
        <div className="bg-white shadow-lg rounded-lg p-8 mx-auto"     style={{ border: '1px solid #D9D8D8' }}>

          <div className="flex items-center justify-between mb-6">
            <h1 className="text-2xl font-semibold text-gray-700">Gerar Pix</h1>
            <div className="inline-flex items-center space-x-2">
           
            </div>
          </div>
          <form className="space-y-6" onSubmit={handlePaymentEFI}>
            <div>
              <label htmlFor="name" className="mb-2 block text-gray-700 font-medium">
                Nome
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={payerFirstName}
                onChange={(e) => setPayerFirstName(e.target.value)}
                placeholder="Nome"
                required
                className="px-4 py-3 rounded-lg border border-gray-300 bg-gray-50 focus:border-green-600 w-full"
              />
            </div>
            <div>
              <label htmlFor="email" className="mb-2 block text-gray-700 font-medium">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                required
                className="px-4 py-3 rounded-lg border border-gray-300 bg-gray-50 focus:border-green-600 w-full"
              />
            </div>
            <div className="flex items-center justify-between">
            <button

  type="submit"
  className="w-full bg-green-600  text-white font-semibold py-3 px-6 rounded-full flex justify-center items-center"
>
  <QRCodeIcon className="w-6 h-6 mr-2" />
  Gerar Código Pix
</button>


            </div>
            <div className="mt-4 text-center text-sm text-gray-500">
            <p className="text-center text-sm text-gray-500">
            <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 ml-1" /> Dieta gerada após Pagamento.

          </p>            </div>
          </form>
        </div>
      </div>
      </>
    )
    }

   
          {/* <button className='bg-black text-white px-2 py-2' onClick={CadastrarWebhook}>Cadastrar WebHook</button>
          <button className='bg-black text-white px-2 py-2' onClick={VerificarWebhook}>Verificar WebHook</button>
          <button className='bg-black text-white px-2 py-2' onClick={DeletarWebhook}>Deletar WebHook</button>

 */}
 {paymentEFI && paymentEFI.status === 'ATIVA' &&
     (
<div className="w-full max-w-md mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
      <div className="p-6 space-y-6">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-green-500">R$ {formatCurrency(paymentEFI.valor.original)} via pix</h2>
        </div>

        <div className="bg-green-50 pt-2 rounded-md text-center">
          <h3 className="text-lg font-semibold text-green-700">Pagamento Seguro</h3>
          <p className="text-green-600">Sua transação está protegida</p>
        </div>

        <div className="bg-gray-100  rounded-md text-center">
          <input value={paymentEFI.pixCopiaECola} className="text-gray-700 font-mono bg-gray-100 w-full p-2"></input>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <button
            onClick={() => copiarParaAreaDeTransferencia(paymentEFI.pixCopiaECola)}
            className="flex items-center justify-center px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-900 transition-colors"
          >
            <ClipboardCopyIcon className="w-5 h-5 mr-2" />
            {'Copiar Código'}
          </button>
          <button
            onClick={() => setVisibleQr(!visibleQr)}
            className="flex items-center justify-center px-4 py-2 border-1 border-green-600 text-green-600 rounded-md hover:bg-green-200 transition-colors"
          >
            <QrCode className="w-5 h-5 mr-2" />
            {visibleQr ? 'Fechar QR Code' : 'Abrir QR Code'}
          </button>
        </div>

        {visibleQr && (
          <QRCode
      value={paymentEFI.pixCopiaECola}
        size={256} // Tamanho do QR Code
        bgColor={"#ffffff"} // Cor de fundo
        fgColor={"#000000"} // Cor do QR Code
        level={"L"} // Nível de correção de erro ("L", "M", "Q", "H")
        includeMargin={true} // Incluir margem no QR Code
      />
        )} 
        <button
              onClick={() => handleCancelarPaymentEFI(paymentEFI.txid)}
              className="w-full flex items-center justify-center px-4 py-2 bg-white text-red-600 border-1 border-red-600 rounded-md hover:bg-red-50 transition-colors"
            >
              <X className="w-5 h-5 mr-2" />
              Cancelar Pagamento
            </button>
          

{/* <div className="grid grid-cols-2 gap-4 mt-4">
              <button
                onClick={()=>handleCancelarPaymentEFI()}
                className="flex items-center justify-center px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
              >
                <X className="w-5 h-5 mr-2" />
                Cancelar Pagamento
              </button>
              <button
                onClick={'handlePaid'}
                className="flex items-center justify-center px-3 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors"
              >
                <Check className="w-5 h-5 mr-2" />
                Já Paguei
              </button>
            </div> */}

      </div>
    </div>

)}

    </div>
  );
}
