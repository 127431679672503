import React, { useEffect, useState } from "react";
import "../Styles/App.css";
import HamburgerMenu from "../Menu/HamburgerMenu";
import ChatContainer from "../ChatContainer/ChatContainer";
import "bootstrap/dist/css/bootstrap.css";
import axios from "axios";
import { UserAuth } from "../../context/AuthContext";
import ErrorCard from "../Error/ErrorCardHome";
import LoadInicial from "../LoadingComponent/LoadInicial";
import Step from '../../img/FacilitaNutri-Step.svg'

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { user, adicionarErro, adicionarRequest, retornaTicketUsado} = UserAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [suporte, setSuporte] = useState(false);
  const [TicketsUsados, setTicketsUsados] = useState(false)
  const { retornaTicket } = UserAuth();
  const [answer, setAnswer] = useState([]);
  const [userTickets, setUserTickets] = useState("");

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };



  useEffect(() => {
    retornaTicket()
      .then(async (tickets) => {
        if (tickets !== null) {
          setUserTickets(tickets);
        } else {
          setUserTickets("Nenhum ticket disponível");
        }
      })
      .catch((error) => {
        setUserTickets("Erro ao obter os tickets");
      });
  }, [retornaTicket]);

  useEffect(() => {
      retornaTicketUsado()
      .then((tickets) => {
        if (tickets !== null) {
          if(tickets === 0 || tickets === undefined){
              setTicketsUsados(false)
            }else{
              setTicketsUsados(true)
            }
        } else {
          console.log("Nenhum ticket disponível");
        }

      })
      .catch((error) => {
        console.log("Erro ao obter os tickets");
      });
  }, [retornaTicketUsado]);

  const wpp = () => {
    if (userTickets === "DietaTreino" || userTickets === "DietaNormal") {
      setSuporte(!suporte);
    } else {
      const numeroum = "5524999375062";
      const numerodois = "5524992178767";

      const numeros = [];
      function selecionarNumeroAleatoriamente() {
        numeros.push(numeroum);
        numeros.push(numerodois);

        const indiceAleatorio = Math.floor(Math.random() * numeros.length);
        return numeros[indiceAleatorio];
      }

      const whatsappURL = `https://api.whatsapp.com/send?phone=${selecionarNumeroAleatoriamente()}`;

      // Abre uma nova janela ou guia para iniciar a conversa no WhatsApp
      window.open(whatsappURL, "_blank");
    }
  };

  const sendRequestTreinoF = async (data, treino) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/GerarDietaTreinoF`,
        { data: data, treino: treino, uid: user.uid }
      );
      setAnswer(result.data.answer);
      return;
    } catch (error) {
      console.error("Erro ao enviar a requisição:", error);

      // Adicionando mais detalhes ao erro, se disponível
      if (error.response) {
        // A resposta foi feita pelo servidor com um status fora do range de 2xx
        console.error("Detalhes do erro:", error.response.data);
        adicionarErro(`Erro ao enviar a requisição: ${error.response.data}`);

        // Aguardar antes de tentar novamente
      } else if (error.request) {
        // O pedido foi feito mas não houve resposta
        adicionarErro(
          `Erro de rede ou servidor não respondeu. Tentativa: 1 ${error.message}`
        );
        await new Promise((res) => setTimeout(res, 5000));
        const retries = 2
        for (let attempt = 1; attempt <= retries; attempt++) {
        try {
          // Segunda tentativa após o delay
          adicionarRequest(`Feita na ${attempt} requisição`)
          const result = await axios.post(
            `${process.env.REACT_APP_API_URL}/GerarDietaTreinoF`,
            { data: data, treino: treino, uid: user.uid }
          );
          setAnswer(result.data.answer);
          return;
        } catch (retryError) {
          console.error(
            "Erro ao enviar a requisição na segunda tentativa:",
            retryError
          );
          adicionarErro(
            `Erro na ${attempt} tentativa de envio da requisição: ${retryError.message}`
          );
          if (attempt === retries) {
            adicionarErro(`Excedido o número de tentativas. Saindo da função. ${retryError.message}`);
            return; // Número máximo de tentativas atingido, sair da função
          }
          await new Promise((res) => setTimeout(res, 4000)); // Aguardar 4 segundos
        }
      } 
    } else {
      // Algum outro erro foi acionado ao tentar fazer a requisição
      console.error("Erro na requisição:", error.message);
      adicionarErro("Erro ao enviar a requisição: " + error.message);
    }
    }
  };

  const sendRequestTreinoM = async (data, treino) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/GerarDietaTreinoM`,
        { data: data, treino: treino, uid: user.uid }
      );
      setAnswer(result.data.answer);
      return;
    } catch (error) {
      console.error("Erro ao enviar a requisição:", error);

      // Adicionando mais detalhes ao erro, se disponível
      if (error.response) {
        // A resposta foi feita pelo servidor com um status fora do range de 2xx
        console.error("Detalhes do erro:", error.response.data);
        adicionarErro(`Erro ao enviar a requisição: ${error.response.data}`);
      } else if (error.request) {
        // O pedido foi feito mas não houve resposta
        adicionarErro(
          `Erro de rede ou servidor não respondeu. Tentativa: 1 ${error.message}`
        );
        await new Promise((res) => setTimeout(res, 5000));
        const retries = 2
        for (let attempt = 1; attempt <= retries; attempt++) {
           try {
          // Segunda tentativa após o delay
          adicionarRequest(`Feita na ${attempt} requisição`)
          const result = await axios.post(
            `${process.env.REACT_APP_API_URL}/GerarDietaTreinoM`,
            { data: data, treino: treino, uid: user.uid }
          );
          setAnswer(result.data.answer);
          return;
        } catch (retryError) {
          console.error(
            "Erro ao enviar a requisição na segunda tentativa:",
            retryError
          );
          adicionarErro(
            `Erro na ${attempt} tentativa de envio da requisição: ${retryError.message}`
          );
          if (attempt === retries) {
            adicionarErro(`Excedido o número de tentativas. Saindo da função. ${retryError.message}`);
            return; // Número máximo de tentativas atingido, sair da função
          }
          await new Promise((res) => setTimeout(res, 4000)); // Aguardar 4 segundos
        }
      } 
  }else {
    // Algum outro erro foi acionado ao tentar fazer a requisição
    console.error("Erro na requisição:", error.message);
    adicionarErro("Erro ao enviar a requisição: " + error.message);
  }
    }
  };

  const sendRequest = async (data) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/GerarDieta`,
        { data: data, uid: user.uid }
      );
      setAnswer(result.data.answer);
      return;
    } catch (error) {
      // Adicionando mais detalhes ao erro, se disponível
      if (error.response) {
        // A resposta foi feita pelo servidor com um status fora do range de 2xx
        console.error("Detalhes do erro:", error.response.data);
        adicionarErro(`Erro ao enviar a requisição: ${error.response.data}`);
      } else if (error.request) {
        // O pedido foi feito mas não houve resposta
        adicionarErro(
          `Erro de rede ou servidor não respondeu. Tentativa: 1 ${error.message}`
        );
        await new Promise((res) => setTimeout(res, 5000));
        const retries = 2
        for (let attempt = 1; attempt <= retries; attempt++) {
        try {
          // Segunda tentativa após o delay
          adicionarRequest(`Feita na ${attempt} requisição`)
          const result = await axios.post(
            `${process.env.REACT_APP_API_URL}/GerarDieta`,
            { data: data, uid: user.uid }
          );
          setAnswer(result.data.answer);
          return;
        } catch (retryError) {
          console.error(
            "Erro ao enviar a requisição na segunda tentativa:",
            retryError
          );
          adicionarErro(
            `Erro na ${attempt} tentativa de envio da requisição: ${retryError.message}`
          );
          if (attempt === retries) {
            adicionarErro(`Excedido o número de tentativas. Saindo da função. ${retryError.message}`);
            return; // Número máximo de tentativas atingido, sair da função
          }
          await new Promise((res) => setTimeout(res, 4000)); // Aguardar 4 segundos
        }
      } 
      } else {
        console.error("Erro na requisição:", error.message);
        adicionarErro("Erro ao enviar a requisição: " + error.message);
      }
    }
  };

  // Exemplo de uso:

  return (
    <div className="App pb-5">
      {TicketsUsados === true ? (
        <LoadInicial onClose={() => setTicketsUsados(false)} />
      ) : (
        <>
          <header className="App-header p-3">
            <HamburgerMenu
              isOpen={isMenuOpen}
              toggleMenu={toggleMenu}
              tickets={userTickets}
            />
          </header>
          <br></br>
          <img 
  src={Step} 
  alt="img" 
  className="img-responsive d-md-none" 
  style={{ width: '70%', maxWidth: '70%' }}
/>
          <main className="App-main">
            <br />
            <ChatContainer
              sendRequest={sendRequest}
              sendRequestTreinoF={sendRequestTreinoF}
              sendRequestTreinoM={sendRequestTreinoM}
              answer={answer}
            />
          </main>
          {suporte && (
            <ErrorCard
              title={"Monte Sua Dieta"}
              message={
                "Preencha as informações novamente, selecione seus alimentos e depois clique em montar dieta"
              }
              onClose={wpp}
            />
          )}
          <br />
          <br />
          <div></div>
        </>
      )}
    </div>
  );
}

export default Home;