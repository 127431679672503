import React from 'react';
import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(70, 195, 33, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 20px rgba(70, 195, 33, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(70, 195, 33, 0);
  }
`;

const LoadingSpinnerS = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: rgba(70, 195, 33, 0.1);
  color: #46C321;
  border: 5px solid rgba(70, 195, 33, 0.3);
  animation: ${pulse} 2s infinite ease-in-out;
  margin-bottom: 20px;  // Espaçamento adicionado entre o spinner e o botão do cartão
`;

export default function LoadingSpinner() {
  return (
    <div>
      <LoadingSpinnerS
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      />
    </div>
  );
}
