import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaCheckCircle, FaDumbbell, FaAppleAlt, FaShoppingCart, FaSync, FaThumbsUp } from 'react-icons/fa';
import GatewayPagamento from '../../EfiPay/GatewayPagamento';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function OrderBumpOverlay({ onConfirm, onCancel, isOpen }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full overflow-hidden">
        <div className="p-6">
          <p className="text-green-600 font-semibold mb-4 text-center">Por apenas R$ 4,99 a mais!</p>
          <div className="space-y-3 mb-6">
            {[
              { icon: FaDumbbell, text: "Treinos personalizados" },
              { icon: FaSync, text: "Modificação com a Nutricionista" },
              { icon: FaThumbsUp, text: "Recomendação Whey e Creatina" },
              { icon: FaAppleAlt, text: "Receitas fit, Lista de substituições" },
              { icon: FaShoppingCart, text: "Lista de compras dos alimentos" }
            ].map(({ icon: Icon, text }, index) => (
              <div key={index} className="flex items-center bg-gray-50 p-2 rounded">
                <Icon className="text-green-500 mr-3 h-5 w-5" />
                <span className="text-sm">{text}</span>
              </div>
            ))}
          </div>
          <div className="flex space-x-4">
            <button
              onClick={onCancel}
              className="flex-1 px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
            >
              Não, obrigado
            </button>
            <button
              onClick={onConfirm}
              className="flex-1 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
            >
              Sim, quero incluir
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function Desafio() {
  const [addOrderBump, setAddOrderBump] = useState(false);
  const [amount, setAmount] = useState(0.01);
  const [showOrderBumpOverlay, setShowOrderBumpOverlay] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setShowOrderBumpOverlay(true);
  }, []);

  const handleChangeOrderBump = () => {
    const baseAmount = 0.01;
    const extraAmount = 0.02;
    setAddOrderBump(!addOrderBump);
    setAmount(!addOrderBump ? baseAmount + extraAmount : baseAmount);
  };

  const handleConfirmOrderBump = () => {
    setAddOrderBump(true);
    setShowOrderBumpOverlay(false);
    handleChangeOrderBump();
  };

  const handleCancelOrderBump = () => {
    setShowOrderBumpOverlay(false);
  };

  const handlePaymentWaiting = () => {
    toast.info('Pagamento pendente. Por favor, finalize o pagamento via Pix e volte para essa página!');
  };

  const handlePaymentSuccess = () => {
    navigate('/comprarealizada');
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
      <OrderBumpOverlay
        onConfirm={handleConfirmOrderBump}
        onCancel={handleCancelOrderBump}
        isOpen={showOrderBumpOverlay}
      />

      <div className="bg-white rounded-lg shadow-xl overflow-hidden w-full max-w-md">
        
        {addOrderBump && (
          <div className="bg-green-50 p-4 border-l-4 border-green-500">
            <div className="flex items-center">
              <FaCheckCircle className="text-green-500 mr-2" />
              <h2 className="text-lg font-semibold text-green-800">Treino Incluído</h2>
            </div>
            <p className="mt-1 text-sm text-green-700">
              Listas de substituições e pdf incluídos!
            </p>
          </div>
        )}

        <div className="p-3 py-4">
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-2">Resumo do Pedido 🛒</h3>
            <div className="bg-gray-50 p-4 rounded">
              <div className="flex justify-between mb-2">
                <span>Dieta para Emagrecer</span>
                <span>R$ 9,99</span>
              </div>
              {addOrderBump && (
                <div className="flex justify-between text-green-600">
                  <span>Treino Personalizado e Pdf</span>
                  <span>R$ 4,99</span>
                </div>
              )}
    
            </div>
          </div>

          <div className="mb-1">
    <p className="text-center text-sm text-gray-500">
    Dieta gerada após o pagamento. Por favor, volte ao site para acessar sua dieta.      <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 ml-1" />
    </p>
  </div>
  
          <GatewayPagamento
            amount={amount}
            handlePaymentSuccess={handlePaymentSuccess}
            handlePaymentWaiting={handlePaymentWaiting}
            addOrderBump={addOrderBump}
          />
        </div>
      </div>
    </div>
  );
}

export default Desafio;