import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';

import FacilitaNutri from '../../img/FacilitaNutri.svg';
import '../Sign/Sign.css'; 

const Signin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { signIn } = UserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await signIn(email, password);
      navigate('/home');
    } catch (e) {
      if (e.message === "Firebase: Error (auth/user-not-found).") {
        setError("Email Incorreto");
      } else if (e.message === "Firebase: Error (auth/wrong-password).") {
        setError("Senha Incorreta");
      }
    }
  };

  return (
    <div className="signin-container">
      <div className="signin-card">
        <div className="text-center">
          <img src={FacilitaNutri} alt="Nutrimage" className="logo-image" />
          <h2 className="signin-heading font-bold">Facilita Nutri - Entrar</h2>
        </div>
        <br></br>
        <form onSubmit={handleSubmit} className="signin-form">
          <div className="form-group">
            <input
              onChange={(e) => setEmail(e.target.value)}
              className="form-input"
              type="email"
              placeholder="Email"
              required
            />
          </div>
          <div className="form-group">
            <input
              onChange={(e) => setPassword(e.target.value)}
              className="form-input"
              autoComplete="current-password"
              type="password"
              placeholder="Senha"
              required
            />
          </div>
          <br></br>
          {error && <p className="error-message">{error}</p>}
          <button className="signin-button">ENTRAR</button>
        </form>
        <div className="signin-links">
          <p>
            Esqueceu sua senha?{' '}
            <Link to="/redefinirsenha" className="forgot-password-link">
              REDEFINIR
            </Link>
          </p>
          <Link to="/signup" className="signup-link">
             <strong>CRIAR UMA CONTA</strong>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Signin;
