import React from "react";
import Footer from "../Footer/Footer";

export default function Termos() {
    return (
        <div>
            <header className="pt-3 text-center">
                <h1>Termos e Condições de Uso</h1>
            </header>
            <main>
                <section className="text-justify text-green">
                    <h2 className="text-center mt-5 text-green-500">Natureza da Plataforma</h2>
                    <p className="w-[90%] md:w-[65%] mx-auto">
                        A Facilita Nutri utiliza algoritmos avançados para processar as informações fornecidas por você, como preferências alimentares, restrições dietéticas, metas de saúde e histórico médico, para oferecer recomendações personalizadas adequadas ao seu perfil. Lembramos que as orientações fornecidas pela plataforma são de natureza geral e informativa, e não substituem a importância de um acompanhamento individualizado por um profissional de saúde qualificado.

                        Ao utilizar a Facilita Nutri, você terá acesso a recomendações que podem ajudar na promoção de uma alimentação equilibrada e saudável, conforme as informações fornecidas. No entanto, é essencial entender que a plataforma não consegue levar em conta todas as particularidades específicas de cada caso, como condições médicas pré-existentes, interações medicamentosas ou necessidades dietéticas especiais.

                        Recomendamos que você compartilhe as orientações da Facilita Nutri com um nutricionista ou médico antes de realizar qualquer mudança significativa em sua dieta. Profissionais de saúde podem oferecer avaliações detalhadas e personalizadas, levando em consideração fatores que não podem ser adequadamente abordados apenas por meio de algoritmos.

                        Ressaltamos ainda que as informações fornecidas à Facilita Nutri devem ser precisas e atualizadas para garantir que as recomendações sejam as mais adequadas possíveis. A plataforma é uma ferramenta de apoio e conscientização, mas a responsabilidade final por suas escolhas alimentares e de saúde permanece com você e seu profissional de saúde de confiança.
                    </p>
                </section>

                <section className="text-justify">
                    <h2 className="text-center mt-5 text-green-500">Orientação Profissional</h2>
                    <p className="w-[90%] md:w-[65%] mx-auto">
                        Recomendamos sempre que você consulte um nutricionista ou outro profissional de saúde antes de iniciar qualquer dieta ou plano alimentar. A Facilita Nutri não consegue levar em consideração todas as nuances individuais de saúde, alergias, intolerâncias alimentares, condições médicas preexistentes ou outros fatores que um profissional de saúde qualificado pode avaliar. Sua saúde é uma prioridade, e somente um profissional devidamente licenciado e com formação adequada pode fornecer orientações personalizadas, embasadas em conhecimento científico, considerando seu histórico de saúde, exames médicos e outros aspectos relevantes.

                        É importante entender que a Facilita Nutri é uma ferramenta que opera com base em algoritmos e informações fornecidas por você, mas não pode substituir o discernimento clínico e a expertise de um profissional de saúde humano. A interação direta com um profissional permite uma análise completa e abrangente, considerando todos os aspectos da sua saúde e bem-estar.

                        Lembre-se de que cada pessoa é única, e as orientações nutricionais devem ser adaptadas a cada caso individual. Um profissional de saúde qualificado pode avaliar seu perfil de saúde de maneira holística, levando em conta fatores como histórico médico, medicamentos em uso, exames laboratoriais e outras informações relevantes. Portanto, ao utilizar a Facilita Nutri, use as informações fornecidas como um complemento à orientação de um profissional de saúde, e não como um substituto completo.

                        Nunca hesite em buscar a opinião de um especialista antes de tomar decisões importantes relacionadas à sua saúde e nutrição. Isso garantirá que você esteja tomando as medidas mais adequadas e seguras para alcançar suas metas de saúde de maneira sustentável e informada.
                    </p>
                </section>

                <section className="text-justify">
                    <h2 className="text-center mt-5 text-green-500">Limitações da Inteligência Artificial</h2>
                    <p className="w-[90%] md:w-[65%] mx-auto">
                        Entenda que a Facilita Nutri opera com base em algoritmos e dados disponíveis até o momento de sua última atualização. Embora envidemos esforços contínuos para fornecer orientações precisas e relevantes, a plataforma pode conter erros ou não estar atualizada com as informações médicas mais recentes. A ciência da nutrição e da saúde está em constante evolução, e novas descobertas podem influenciar as abordagens recomendadas.

                        Portanto, é essencial que você use o bom senso ao interpretar as recomendações geradas pela plataforma. Considere-as como um ponto de partida para sua jornada de cuidados com a saúde, mas esteja ciente de que a consulta regular a fontes confiáveis, como profissionais de saúde atualizados e pesquisas científicas recentes, é fundamental para tomar decisões informadas.

                        Lembre-se também de que sua saúde é única e pode ser afetada por uma série de fatores individuais. A Facilita Nutri não pode substituir a avaliação minuciosa de um profissional de saúde, que pode levar em conta sua situação específica, histórico médico, exames laboratoriais e outros elementos relevantes.

                        Ao utilizar a Facilita Nutri, equilibre suas decisões com informações obtidas de fontes confiáveis e atualizadas, e não hesite em buscar o aconselhamento de um profissional de saúde sempre que necessário. A colaboração entre a tecnologia e a expertise humana é fundamental para garantir que você esteja fazendo escolhas saudáveis e bem fundamentadas para o seu bem-estar.
                    </p>
                </section>

                <section className="text-justify">
                    <h2 className="text-center mt-5 text-green-500">Responsabilidade e Riscos</h2>
                    <p className="w-[90%] md:w-[65%] mx-auto">
                        Ao usar a Facilita Nutri, você concorda em assumir total responsabilidade por suas decisões em relação à sua saúde e dieta. A utilização da plataforma é por sua conta e risco, e não nos responsabilizamos por qualquer consequência adversa resultante do uso ou má interpretação das recomendações da Facilita Nutri. É fundamental compreender que as recomendações geradas pela plataforma são baseadas em informações fornecidas por você e processadas por algoritmos, mas não substituem a orientação de um profissional de saúde qualificado.

                        Ao optar por utilizar a Facilita Nutri, você deve estar ciente dos limites inerentes à tecnologia e do fato de que decisões de saúde devem ser tomadas com base em uma variedade de fatores, incluindo informações médicas atualizadas e aconselhamento especializado. Recomendamos fortemente que você busque a orientação de um nutricionista, médico ou outro profissional de saúde antes de implementar qualquer mudança significativa em sua dieta ou estilo de vida, a fim de garantir que suas escolhas sejam compatíveis com suas necessidades individuais.

                        Lembre-se de que sua saúde é uma prioridade, e tomar decisões informadas é essencial para promover seu bem-estar a longo prazo. A Facilita Nutri pode ser uma ferramenta útil, mas deve ser usada com discernimento e em conjunto com a orientação de profissionais de saúde competentes.
                    </p>
                </section>

                <section className="text-justify">
                    <h2 className="text-center mt-5 text-green-500">Modificações nos Termos</h2>
                    <p className="w-[90%] md:w-[65%] mx-auto">
                        Reservamo-nos o direito de atualizar ou modificar estes termos a qualquer momento. Recomendamos que você revise periodicamente esta página para se manter informado sobre quaisquer alterações. O uso contínuo da Facilita Nutri após quaisquer modificações nos termos indica sua aceitação dessas mudanças. É importante estar ciente dos termos mais recentes ao utilizar a plataforma, a fim de garantir que você esteja ciente de quaisquer atualizações que possam afetar sua interação com a Facilita Nutri.

                        Entendemos a importância de manter nossos usuários informados e asseguramos que qualquer atualização nos termos seja feita com transparência e considerando o melhor interesse dos usuários. Agradecemos por escolher a Facilita Nutri como parte de sua jornada de saúde e bem-estar, e estamos comprometidos em fornecer uma plataforma confiável e eficaz para auxiliá-lo em suas metas nutricionais.
                    </p>
                </section>

                <section className="text-justify">
                    <h2 className="text-center mt-5 text-green-500">Concordância com os Termos</h2>
                    <p className="w-[90%] md:w-[65%] mx-auto">
                        Ao continuar a usar a Facilita Nutri, você indica que leu, compreendeu e concordou com estes termos e condições de uso. Se você não concordar com esses termos, solicitamos que não utilize a plataforma. A aceitação destes termos é um requisito fundamental para a utilização da Facilita Nutri.

                        Caso você tenha alguma dúvida ou preocupação sobre os termos, funcionamento da plataforma ou qualquer outro assunto relacionado, encorajamos você a entrar em contato conosco através dos canais de suporte indicados em nosso site. Estamos aqui para fornecer esclarecimentos e assistência, garantindo que você tenha a melhor experiência possível ao utilizar a Facilita Nutri como parte de sua jornada de saúde e nutrição.

                        Agradecemos sua compreensão e sua escolha em fazer uso da nossa plataforma para ajudá-lo a tomar decisões informadas em relação à sua dieta e bem-estar.
                    </p>
                </section>

                <section className="text-justify">
                    <h2 className="text-center mt-5 text-green-500">Exclusão de Garantias</h2>
                    <p className="w-[90%] md:w-[65%] mx-auto">
                        A Facilita Nutri é fornecida "como está", sem qualquer garantia expressa ou implícita. Não garantimos que a plataforma estará livre de erros ou que atenderá às suas expectativas. O uso da Facilita Nutri é por sua conta e risco, e não oferecemos garantias em relação à precisão, confiabilidade ou adequação das informações fornecidas.

                        Estamos empenhados em melhorar continuamente a qualidade da Facilita Nutri, mas é importante que você esteja ciente de que a plataforma é uma ferramenta de suporte e não uma solução definitiva para todas as suas necessidades de saúde e nutrição. Recomendamos que você utilize a plataforma com discernimento e busque a orientação de profissionais de saúde para decisões mais específicas e críticas.

                        A Facilita Nutri é uma ferramenta de apoio, desenvolvida para fornecer orientações gerais de nutrição, e não se destina a substituir a orientação médica profissional. É essencial que você complemente as informações fornecidas pela plataforma com uma consulta a um profissional de saúde qualificado, especialmente em casos que envolvam condições médicas específicas ou necessidades dietéticas personalizadas.
                    </p>
                </section>
            </main>
            <Footer />
        </div>
    );
}
